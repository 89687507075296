const API_PREFIX = process.env.API_PREFIX;

export const API_ROUTES = {
  LOGIN: `${API_PREFIX}/auth/login`,
  LOGOUT: `${API_PREFIX}/auth/logout`,
  FORGOT_PASSWORD: `${API_PREFIX}/auth/forgot-password`,
  RESET_PASSWORD: `${API_PREFIX}/auth/reset-password`,
  USER_APPLICATION: `${API_PREFIX}/auth/application`,
  CURRENT_USER: `${API_PREFIX}/current-user`,
  CURRENT_USER_WATCHLIST_ACTIVE_COUNT: `${API_PREFIX}/current-user/watchlist-active-count`,
  CURRENT_USER_ATTACHMENTS: `${API_PREFIX}/current-user/attachments`,
  CURRENT_USER_ATTACHMENTS_DELETE: `${API_PREFIX}/current-user/attachments/delete`,
  CURRENT_USER_NOTIFICATIONS: `${API_PREFIX}/current-user/notifications`,
  CURRENT_COMPANY: `${API_PREFIX}/current-company`,
  CURRENT_COMPANY_FULL: `${API_PREFIX}/current-company/full`,
  CURRENT_COMPANY_LICENSES: `${API_PREFIX}/current-company/licenses`,
  CURRENT_COMPANY_SUBSCRIBE: `${API_PREFIX}/current-company/licenses/subscribe`,
  CURRENT_COMPANY_PRO_SUBSCRIBE: `${API_PREFIX}/current-company/create-pro-subscription`,
  CURRENT_COMPANY_CHECK_PRO_SUBSCRIPTION: `${API_PREFIX}/current-company/validate-pro-subscription`,
  CURRENT_COMPANY_SET_ACH_ACCOUNT: `${API_PREFIX}/current-company/create-ach-account`,
  CURRENT_COMPANY_SET_DEFAULT_PAYMENT_METHOD: `${API_PREFIX}/current-company/set-default-payment-type`,
  CURRENT_COMPANY_CHECK_SUBSCRIPTION: `${API_PREFIX}/current-company/licenses/validate-subscription`,
  CURRENT_COMPANY_CANCEL_SUBSCRIPTION: `${API_PREFIX}/current-company/licenses/cancel-subscription`,
  CURRENT_COMPANY_USERS: `${API_PREFIX}/current-company/users`,
  CURRENT_COMPANY_BOXES: `${API_PREFIX}/current-company/boxes`,
  CURRENT_COMPANY_CONTACTS: `${API_PREFIX}/current-company/contacts`,
  CURRENT_COMPANY_PRICING_PLANS: `${API_PREFIX}/current-company/pricing-plans`,
  LICENSE_EXISTS: `${API_PREFIX}/licenses/check-if-exists`,
  BRANDS: `${API_PREFIX}/brands`,
  BRANDS_DELETE: `${API_PREFIX}/brands/delete`,
  PRODUCT_LINES: `${API_PREFIX}/product-lines`,
  CATEGORY_IMAGES: `${API_PREFIX}/admin/taxonomies/category-images`,
  CATEGORY_TYPES: `${API_PREFIX}/category-types`,
  CATEGORIES: `${API_PREFIX}/categories`,
  PRODUCTION_TYPES: `${API_PREFIX}/production-types`,
  TRIM_TYPES: `${API_PREFIX}/trim-types`,
  STRAIN_TYPES: `${API_PREFIX}/strain-types`,
  STRAINS: `${API_PREFIX}/strains`,
  STRAINS_METRC: `${API_PREFIX}/strains-metrc`,
  PAYMENT_TERMS: `${API_PREFIX}/payment-terms`,
  GROW_MEDIA: `${API_PREFIX}/grow-media`,
  METRC_PACKAGE: `${API_PREFIX}/metrc/packages`,
  METRC_PACKAGES_ACTIVE_LIST: `${API_PREFIX}/metrc/packages/list/active`,
  METRC_PACKAGES_FROM_HARVESTS: `${API_PREFIX}/metrc/packages-from-harvests`,
  METRC_HARVESTS_ACTIVE_LIST: `${API_PREFIX}/metrc/harvests/list/active`,
  METRC_HARVESTS_CREATE_PACKAGES: `${API_PREFIX}/metrc/packages-from-harvests`,
  METRC_PACKAGES_POLLING: `${API_PREFIX}/metrc/packages/polling`,
  METRC_PACKAGES_POLLING_PROGRESS: `${API_PREFIX}/metrc/packages/polling/progress`,
  PRODUCTION_DEFAULTS: `${API_PREFIX}/production/defaults`,
  PRODUCTION_CURRENT: `${API_PREFIX}/production/current`,
  PRODUCTION_PLANTS_POLLING: `${API_PREFIX}/production/plants/polling`,
  PRODUCTION_PLANTS_POLLING_PROGRESS: `${API_PREFIX}/production/plants/polling/progress`,
  PRODUCTION_HARVESTS: `${API_PREFIX}/production/harvests`,
  PRODUCTION_HARVESTS_POLLING: `${API_PREFIX}/production/harvests/polling`,
  PRODUCTION_HARVESTS_POLLING_PROGRESS: `${API_PREFIX}/production/harvests/polling/progress`,
  PRODUCTION_STRAINS_CURRENT: `${API_PREFIX}/production/strains`,
  METRC_ITEM: `${API_PREFIX}/metrc/items`,
  METRC_ITEMS_ACTIVE_LIST: `${API_PREFIX}/metrc/items/list/active`,
  METRC_LOCATIONS_ACTIVE_LIST: `${API_PREFIX}/metrc/locations/list/active`,
  METRC_FACILITIES_LIST: `${API_PREFIX}/metrc/facilities/list`,
  METRC_ITEM_PRICE_SUGGESTIONS: `${API_PREFIX}/metrc/item-price-suggestions`,
  METRC_ITEM_SUPPLIERS: `${API_PREFIX}/metrc/item-suppliers`,
  INGREDIENTS: `${API_PREFIX}/ingredients`,
  PRODUCTS: `${API_PREFIX}/products`,
  PRODUCTS_IMAGES: `${API_PREFIX}/products/images`,
  PRODUCTS_IMAGES_DELETE: `${API_PREFIX}/products/images/delete`,
  LISTINGS: `${API_PREFIX}/listings`,
  LISTINGS_VALIDATE: `${API_PREFIX}/listings/validate`,
  LISTINGS_IMAGES: `${API_PREFIX}/listings/images`,
  LISTINGS_IMAGES_DELETE: `${API_PREFIX}/listings/images/delete`,
  LISTING_DRAFTS: `${API_PREFIX}/listings/drafts`,
  AUCTIONS: `${API_PREFIX}/auctions`,
  TAXONOMIES: `${API_PREFIX}/taxonomies`,
  COMPANIES: `${API_PREFIX}/companies`,
  USERS: `${API_PREFIX}/users`,
  USERS_MESSENGER: `${API_PREFIX}/messenger/users`,
  SALES: `${API_PREFIX}/sales`,
  PURCHASES: `${API_PREFIX}/purchases`,
  BUYER_REQUESTS: `${API_PREFIX}/buyer-requests`,
  PUBLIC_INVOICES: `${API_PREFIX}/public/invoices`,
  TRANSFERS: `${API_PREFIX}/transfers`,
  TRANSFER_TEMPLATE: `${API_PREFIX}/transfers/template`,
  TRANSFER_MANIFEST: `${API_PREFIX}/transfers/manifest`,
  TRANSFER_FROM_MANIFEST: `${API_PREFIX}/transfers/createFromManifest`,
  TRANSFER_ADD_CH: `${API_PREFIX}/transfers/addCannahauler`,
  INVENTORY_LISTINGS: `${API_PREFIX}/inventory-listings`,
  CONVERT_MEASURE_UNIT: `${API_PREFIX}/general/convert/measure-unit`,
  CONVERT_PRICE_PER_UNIT: `${API_PREFIX}/general/convert/price-per-unit`,
  PUBLIC_IMAGES: `${API_PREFIX}/public-images`,
  LEAFLINK: `${API_PREFIX}/leaflink`,
  REORDER_INVENTORY: `${API_PREFIX}/inventory-reorders`,
  ORDERS: `${API_PREFIX}/orders`,
  BUYER_ORDERS: `${API_PREFIX}/buyer-orders`,
  IN_HOUSE_DRIVERS: `${API_PREFIX}/licenses/get-drivers`,
  NEW_IN_HOUSE_DRIVER: `${API_PREFIX}/licenses/add-driver`,
  UPDATE_IN_HOUSE_DRIVER: `${API_PREFIX}/licenses/update-driver`,
  DELETE_IN_HOUSE_DRIVER: `${API_PREFIX}/licenses/delete-driver`,
  OTHER_LICENSES: `${API_PREFIX}/licenses/other`
};
